import * as React from 'react';
import { useRef } from 'react';

import { WindowLocation } from '@reach/router';
import classNames from 'classnames';

import Link from '@components/Link';

import { localizedNavigate } from '../../utils/localized-navigate';
import { Select } from '../Forms/Select/index';

import { CLUB_TAB_ROUTES } from '@domain/clubs';

import { Drupal } from '@typings/drupal';
import { DISTRICT_TAB_ROUTES } from '@domain/districts';

export const Submenu: React.FC<{
  items: Drupal.MenuItem[];
  fullWidth?: boolean;
  location:
    | Drupal.Location
    | Drupal.DrupalLocation
    | WindowLocation<unknown>
    | undefined;
}> = ({ items, fullWidth }) => {
  interface TabRouteDetails {
    value: string;
    label: string;
  }

  const options: TabRouteDetails[] = items.map(item => {
    return { value: item.path, label: item.label };
  });

  const selectProps = {
    id: 'submenu',
    name: 'submenu',
    label: '',
    options,
  };

  const handleChange = (item: TabRouteDetails) => {
    localizedNavigate(item.value);
  };

  const url =
    typeof window.location !== 'undefined' ? window.location.href : '';

  const isFromClubParentTab =
    CLUB_TAB_ROUTES.filter(item => url.includes(item)).length > 0;

  const isFromDistrictParentTab =
    DISTRICT_TAB_ROUTES.filter(item => url.includes(item)).length > 0;

  const filteredOptions = options.filter(option => {
    return url.includes(option.value);
  });
  const previousActiveTabValue = useRef(filteredOptions);
  let activeValue = filteredOptions;
  let activeDistrictValue = filteredOptions;
  if (url.includes('/club/')) {
    activeValue = isFromClubParentTab
      ? filteredOptions
      : previousActiveTabValue.current;

    if (activeValue.length === 0)
      activeValue = localStorage.getItem('activeTabValue')
        ? JSON.parse(localStorage.getItem('activeTabValue') as string)
        : [];
    previousActiveTabValue.current = activeValue;
    window.localStorage.setItem('activeTabValue', JSON.stringify(activeValue));
  }

  if (url.includes('/district/')) {
    activeDistrictValue = isFromDistrictParentTab
      ? filteredOptions
      : previousActiveTabValue.current;

    if (activeDistrictValue.length === 0)
      activeDistrictValue = localStorage.getItem('activeDistrictTabValue')
        ? JSON.parse(localStorage.getItem('activeDistrictTabValue') as string)
        : [];
    previousActiveTabValue.current = activeDistrictValue;
    window.localStorage.setItem(
      'activeDistrictTabValue',
      JSON.stringify(activeDistrictValue)
    );
  }

  return (
    <nav
      data-label="Submenu"
      className={`submenu block ${
        fullWidth ? 'border-b-3 border-gray-300-60' : ''
      }`}
    >
      <div className="tablet:hidden">
        <Select
          {...selectProps}
          onChange={item => handleChange(item as TabRouteDetails)}
          isSearchable={false}
          value={activeValue}
        />
      </div>
      <ul
        className={`hidden tablet:flex ${
          fullWidth ? '-ml-6' : 'border-b-2 border-gray-300-60'
        }`}
      >
        {items.map(item => {
          const isActiveItem = item.label === activeValue[0]?.label;

          return (
            <li key={item.id}>
              <Link
                to={item.path}
                noLinkStyles
                className={classNames(
                  'pb-4 desktop-m:mr-6 tablet:mr-4 border-b-4 border-transparent block hover:border-dark-blue-400 focus:border-dark-blue-400',
                  { 'border-dark-blue-400 text-dark-blue-400': isActiveItem }
                )}
                activeClassName="border-dblue text-dblue font-bold"
              >
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
